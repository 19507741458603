import React, { useState } from 'react'
import '../../index.css'
import services from '../../assets/json/services.json'
import postFormResponse, { addDataToFirestore } from '../../controllers/contact/contact'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CaptchaTest from '../../utils/captcha'; 
import {validateCaptcha, loadCaptchaEnginge } from 'react-simple-captcha';
const Contact = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: ""
    })
    function validateFormData(data) {
        let validatephoneNumber = /^\d{10}$/.test(data.phone)
        console.log("Inside validateFormData")
        if(!validatephoneNumber) {
            console.log("Inside Phone")
            toast.warn('Enter Valid Phone Number', {
                position :"top-center",
                autoClose : 5000,
                hideProgressBar : false,
                newestOnTop : false,
                closeOnClick : true,
                rtl : false,
                draggable : false,
                theme : "colored" 
            })
            return true
        }
        if(!data.service || data.service === "") {
            toast.warn('Select the Service', {
                position :"top-center",
                autoClose : 5000,
                hideProgressBar : false,
                newestOnTop : false,
                closeOnClick : true,
                rtl : false,
                draggable : false,
                theme : "colored" 
            })
            return true
        }
        return false
    }

    function postData(e) {
        e.preventDefault()
        console.log(data);
            let user_captcha = document.getElementById('user_captcha_input').value;
            if (validateCaptcha(user_captcha)===true) {
                console.log("Inside Captcha")
                if(!validateFormData(data)) {
                    console.log("Inside Validate Form Data")
                    addDataToFirestore(data).then((result) => {
                        console.log("Inside Post Call")
                        toast.success('Our Team will Contact you shortly \n Thank you for Contacting Vedic Tax', {
                            position :"top-center",
                            autoClose : 5000,
                            hideProgressBar : false,
                            newestOnTop : false,
                            closeOnClick : true,
                            rtl : false,
                            draggable : false,
                            theme : "colored" 
                        }
                        )
                        clearForm()
                    }).catch((err) => {
                        console.log("Inside Catch")
                        toast.error('Something went wrong', {
                            position :"top-center",
                            autoClose : 5000,
                            hideProgressBar : false,
                            newestOnTop : false,
                            closeOnClick : true,
                            rtl : false,
                            draggable : false,
                            theme : "colored" 
                        }
                        )
                    });
                }
            }
            else {
                toast.error('Captcha does not matched' , {
                    position :"top-center",
                    autoClose : 5000,
                    hideProgressBar : false,
                    newestOnTop : false,
                    closeOnClick : true,
                    rtl : false,
                    draggable : false,
                    theme : "colored"
                })
                document.getElementById('user_captcha_input').value = "";
            }
    }

    function clearForm() {
        setData(
            {
                name: "",
                email: "",
                phone: "",
                service: "",
                message: ""
            }
        )
        loadCaptchaEnginge(6); 
        document.getElementById('user_captcha_input').value = "";
    }

    function handleChage(e) {
        const { name, value } = e.target
        setData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    return (
        <section>
            <ToastContainer />
            <div className="row justify-content-center" style={{ marginTop: "30px" }}>
                <div className="col-12">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-4 contact-us-title">Contact Us</h4>
                        <p className="text-muted para-desc mx-auto mb-0">Thanks for visiting! Tell us more about your requirement. We will serve you the best</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className='contactcol'>
                            <div className='icon'>
                                <i className="fa fa-phone" style={{ color: "#325580" }}></i>
                            </div>
                            <div>
                                <p className='contactfield'> Phone</p>
                            </div>
                            <div>
                                <p className="text-muted para-desc mx-auto mb-0 contactvalue"> +91 9502296604</p>
                                <p className="text-muted para-desc mx-auto mb-0 contactvalue"> +91 8500252213</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className='contactcol'>
                            <div className='icon'>
                                <i className="fa fa-envelope" style={{ color: "#325580" }}></i>
                            </div>
                            <div>
                                <p className='contactfield'> Email</p>
                            </div>
                            <div>
                                <p className="text-muted para-desc mx-auto mb-0 contactvalue" style={{ marginTop: "30px" }}>vedictax@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className='contactcol'>
                            <div className='icon'>
                                <i className="fa fa-map-marker" style={{ color: "#325580" }}></i>
                            </div>
                            <div>
                                <p className='contactfield'> Address</p>
                            </div>
                            <div>
                                <div className='contactvalue'>
                                    <p className="text-muted para-desc mx-auto mb-0"> Flat 401, Maruthi Plaza, Taj Enclave</p>
                                    <p className="text-muted para-desc mx-auto mb-0"> Khairtabad, Hyderabad, Telangana 500004</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" style={{ marginTop: "20px" }}>
                <div className="row">
                    <div className="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d237.94000032859915!2d78.46136450241846!3d17.40986767836423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb973e0acc091b%3A0xdbb12f1e082bb384!2sVedictax%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687896727650!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            allowfullscreen=""
                            className='mt-1 mr-1'
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="col-md-6">
                        <div className='card'>
                            <div className='contactcol'>
                                <h2 className='contact-heading'> Send Us A Message</h2>
                            </div>
                            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                <form onSubmit={(e) => postData(e)}>
                                    <div className="form-group contact-form-element ">
                                        <label className="contact-form-label contact-form-label-mandatory">Name</label>
                                        <input
                                            width="50%"
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            aria-describedby="email-help"
                                            placeholder="Your Full Name"
                                            onChange={(e) => handleChage(e)}
                                            value={data.name}
                                            required />
                                    </div>
                                    <div className="form-group contact-form-element ">
                                        <label className="contact-form-label contact-form-label-mandatory">Email address</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            aria-describedby="email-help"
                                            placeholder="Your Email Address"
                                            onChange={(e)=> handleChage(e)}
                                            value={data.email}
                                            required />
                                    </div>
                                    <div className="form-group contact-form-element ">
                                        <label className="contact-form-label contact-form-label-mandatory">Phone </label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phone"
                                            name="phone"
                                            aria-describedby="phone-help"
                                            placeholder="Phone Number"
                                            onChange={(e) => handleChage(e)}
                                            value={data.phone}
                                            required />
                                    </div>
                                    <div className="form-group contact-form-element ">
                                        <label className='contact-form-label contact-form-label-mandatory'>Select Service</label>
                                        <select
                                            className="form-select form-select-sm mb-3 contact-form-select"
                                            aria-label=".form-select-lg example"
                                            aria-describedby="service-help"
                                            placeholder="Select Service"
                                            onChange={(e) => handleChage(e)}
                                            value={data.service}
                                            id="service"
                                            name="service"
                                        >
                                            <option value="" selected> Select the Service </option>
                                            {
                                                services.map(service => {
                                                    return <option
                                                        value={service['value']}
                                                    > {service['name']} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group contact-form-element ">
                                        <label className='contact-form-label'>Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            name="message"
                                            rows="3"
                                            aria-describedby="message-help"
                                            placeholder="Write your Message here!"
                                            value={data.message}
                                            onChange={(e) => handleChage(e)}
                                        ></textarea>
                                    </div>
                                    <div className="form-group contact-form-element ">
                                        <CaptchaTest/>
                                    </div>
                                    <button type="submit" className="btn btn-primary" style={{ marginBottom: "10px" }}>Submit</button>
                                    <div />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
