import './App.css';
import Home from './components/home/Home';
import Navigation from './components/navbar/Navigation';
import { Route, Routes } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import About from './components/aboutus/About';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import KnowledgeBank from './components/knowledgebank/KnowledgeBank';
import Service from './components/service/Service';
import Footer from './components/footer/Footer';

function App() {

  useEffect(() => {
    Aos.init({
      duration:2000
    })
  }, []);

  return (
    <>
      <Navigation></Navigation>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/services' element={<Services/>}></Route>
        <Route path='/services/:id' element={<Service/>}></Route>
        <Route path='/knowledgebank/:id' element={<KnowledgeBank/>}></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
