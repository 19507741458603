import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha';



class CaptchaTest extends Component {

   componentDidMount () {
      loadCaptchaEnginge(6); 
   };
   render() {
       return (<div>
           <div className="container">
               <div className="form-group">
                   <div className="col mt-3">
                       <LoadCanvasTemplate />
                   </div>
                   <div className="col mt-3">
                       <div><input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                   </div>
                     
               </div>

           </div>
       </div>);
   };
}

export default CaptchaTest;