import React, { useEffect, useState } from 'react'
import basictax from '../../assets/images/basic_tax.png'
import paytogovertment from '../../assets/images/pay_tax_to_government.png'
import vedictax from '../../assets/images/logo.png'
import { Carousel, Pagination } from 'react-bootstrap'
import homeDetails from '../../assets/json/home.json'
import { useNavigate } from 'react-router-dom'
const Home = () => {

  const [paginationItems, setPaginationItems] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [feedbacks, setFeedBacks] = useState([])
  const navigate = useNavigate()

  const changePage = (toPage) => {
    let startIndex = ((toPage - 1) * 3);
    setActivePage(toPage)
    setFeedBacks(homeDetails['customerFeedback'].slice(startIndex, startIndex + 3))
  }
  
  const navigateTo = (toUrl) => {
    navigate(toUrl)
  }

  useEffect(() => {
    const detailsCount = homeDetails['customerFeedback'].length
    let temp = parseInt(detailsCount / 3) + ((detailsCount % 3) > 0 ? 1 : 0)
    const toltalCount = temp > 1 ? temp : 0
    let arr = []

    for (let i = 1; i <= toltalCount; i++) {
      arr.push(i)
    }
    setFeedBacks(homeDetails['customerFeedback'].slice(0, 3))
    setPaginationItems(arr);
  }, [])

  return (
    <>
      <section className='bg-dark'>
        <Carousel>
          {
            homeDetails && homeDetails['carouselItems'].map(item => <Carousel.Item key={item.id}>
              <div className='container p-3 flex flex-col justify-center justify-between'>
                <div className='row  align-items-center mx-auto'>
                  <div className='col-md-5'>
                    <img className='d-block w-100' src={require(`../../assets/images/${item.image}`)} style={{ height: "50vh" }} alt="First slide" />
                  </div>
                  <div className='col-md-7 flex flex-col justify-center p-3 text-center rounded text-white'>
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            )
          }
        </Carousel>
      </section>

      <section className='mt-3'>
        <div className="container flex flex-col justify-center justify-between" style={{ overflowX: "hidden" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4" style={{color:"blue"}}>Our Features</h4>
                <p className="text-muted para-desc mx-auto mb-0">If you're in need of tax services, rest assured that we're here to provide the assistance you require.</p>
              </div>
            </div>
          </div>

          <div className='row align-items-center mx-auto'>
            <div className="col-md-4 flex items-center justify-center" data-aos="fade-left">
              <div className="card bg-dark text-white">
                <img className="card-img" src={basictax} style={{ border: "0px" }} alt="Card image" />
              </div>

            </div>
            <div className="col-md-8 flex flex-col justify-center text-center rounded" data-aos="fade-right">
              <h1 className="font-bold leading-none">Why Vedic Tax
              </h1>
              <p className="mt-3">We have been at the forefront of Tax E-filing, guiding customers towards Financial Wellness
                <br />surpassing the mere act of tax-filing
              </p>
              <div className="flex flex-col items-center justify-center flex-row justify-start">
                <button className="btn btn-primary rounded" type="submit" onClick={() => navigateTo('contact')}>File your ITR</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='container'>
        <hr className="my-3" />
      </div>

      <section className='mt-2'>
        <div className="container flex flex-col justify-center justify-between" style={{ overflowX: "hidden" }}>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4" style={{color:"blue"}}>Our Services</h4>
                <p className="text-muted para-desc mx-auto mb-0">As one of the leading and highly trusted Firms, we specialize in providing solutions for filing TDS, GST, and individual Income Tax Returns (ITR)</p>
              </div>
            </div>
          </div>

          <div className='row align-items-center mx-auto'>
            <div className="col-md-8 flex flex-col justify-center p-3 text-center rounded" data-aos="fade-left">
              <h1 className="font-bold leading-none"> Save your Taxes
              </h1>
              <p className="mt-3">we have earned a reputation for being highly trusted. Our dedicated team undergoes rigorous training to assist you in effectively planning and minimizing your tax liability. 
              We take pride in our expertise in simplifying the process of online ITR filing, ensuring a seamless experience for you
              </p>
              <div className="flex flex-col items-center justify-center flex-row justify-start">
                <button className="btn btn-primary rounded" type="submit" onClick={() => navigateTo('services')}>Explore Services</button>
              </div>
            </div>
            <div className="col-md-4 flex items-center justify-center" data-aos="fade-right">
              <div className="card bg-dark text-white">
                <img className="card-img" src={paytogovertment} alt="Card image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='container'>
        <hr className="my-3" />
      </div>

      <section className='mt-2'>
        <div className="container flex flex-col justify-center justify-between">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4" style={{color:"blue"}}>Customer Feedback</h4>
                <p className="text-muted para-desc mx-auto mb-0">Respecting our customers is at the core of our values and business philosophy. 
                We believe in creating an environment where every customer feels valued, heard, and appreciated. 
                </p>
              </div>
            </div>
          </div>
          <div className='bg-dark'>
            <div className='row mx-auto'>
              {
                feedbacks.map(feedback => {
                  return (
                    <div className='d-flex mt-4 flex-col col-md-4' key={feedback.id}>
                      <div className="card m-3" data-aos="zoom-in">
                        <div className="card-body">
                          <h5 className="card-title text-center" style={{fontWeight:"bold"}}>{feedback.name}</h5>
                          <p className="card-text text-center"> {feedback.feedback}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
                )
              }
              <div className='d-flex justify-content-center'>
                <Pagination>
                  {
                    paginationItems.map(pageNum => <Pagination.Item onClick={() => changePage(pageNum)} key={pageNum} active={pageNum == activePage}>
                      {pageNum}
                    </Pagination.Item>)
                  }
                </Pagination>
              </div>
            </div>
          </div>
          <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <p className="text-muted para-desc mx-auto mb-0"> 
                 Respecting our customers is not just a principle but a fundamental part of our organizational culture. We believe that by treating our customers with respect, we foster long-lasting relationships, customer loyalty, and a positive reputation in the industry.
                </p>
              </div>
            </div>
        </div>
      </section>

    </>
  )
}

export default Home
