import React, { useEffect, useState } from 'react'
import services from '../../assets/json/services.json'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import paytogovertment from '../../assets/images/pay_tax_to_government.png'
import '../../index.css'

const Services = () => {
  const navigate = useNavigate()
  const navigateTo = (toUrl) => {
    navigate(toUrl)
  }
  return (
    <section>
      <div className="container flex flex-col justify-center justify-between">
        <div className="row justify-content-center" style={{ marginTop: "30px" }}>
          <div className="col-12">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4" style={{color:"blue", fontWeight : "bold"}}>Our Services</h4>
              <p className="text-muted para-desc mx-auto mb-0">As one of the leading and highly trusted Firms, we specialize in providing solutions for filing TDS, GST, and individual Income Tax Returns (ITR)</p>
            </div>
          </div>
        </div>
        <div className='row align-items-center mx-auto'>
            <div className="col-md-8 flex flex-col justify-center p-3 text-center rounded" data-aos="fade-left">
              <h1 className="font-bold leading-none"> Save your Taxes
              </h1>
              <p className="mt-3">we have earned a reputation for being highly trusted. Our dedicated team undergoes rigorous training to assist you in effectively planning and minimizing your tax liability. 
              We take pride in our expertise in simplifying the process of online ITR filing, ensuring a seamless experience for you
              </p>
              <div className="flex flex-col items-center justify-center flex-row justify-start">
                <button className="btn btn-primary rounded" type="submit" onClick={() => navigateTo('/contact')}>Use Services</button>
              </div>
            </div>
            <div className="col-md-4 flex items-center justify-center" data-aos="fade-right">
              <div className="card bg-dark text-white">
                <img className="card-img" src={paytogovertment} alt="Card image" />
              </div>
            </div>
          </div>


        {/* <div className='row align-items-center mx-auto'>
          <div className="col-md-6 d-none d-md-block flex flex-col justify-center p-3 text-center rounded" data-aos="fade-left">
            <h1 className="font-bold leading-none">About Our Services</h1>
            <p className="mt-3">Dictum aliquam porta in condimentum ac integer
              <br />turpis pulvinar, est scelerisque ligula sem
            </p>
            <div className="flex flex-col items-center justify-center flex-row justify-start">
              <button className="btn btn-primary rounded" type="submit">Explore</button>
            </div>
          </div>
          <div className="col-md-6 flex items-center justify-center" data-aos="fade-right">
            <img src={temp} alt="" style={{ height: "350px" }} />
          </div>
        </div> */}

        <div className='row align-items-center mx-auto mt-3 mb-3'>
          {
            services.map(service => {
              return <div key={service['id']} onClick={() => navigate('/services/' + service['id'])} className='col-md-4 flex flex-col justify-center p-2 text-center rounded' role='button'>
                <Card className='card'>
                  {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                  <div className='servicesCard'>
                  <Card.Body>
                    <Card.Title className = "service-heading">{service['name']}</Card.Title>
                    <Card.Text>
                      {service['description']}
                    </Card.Text>
                    <p className = "services" style={{color : "blue"}}>{service['navigateText:']}</p>
                  </Card.Body>
                  </div>
                </Card>
              </div>
            })
          }
        </div>

      </div>
    </section>
  )
}

export default Services
