import React from 'react'
import aboutus from '../../assets/json/aboutus.json'
import taxhome from '../../assets/images/tax_home.png'


const About = () => {
  var principles = aboutus.principles
  return (
    <section>
      <div className="container" >
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
            <div className="card mb-4 border-0">
              <div className="card-body">
                <h1 className="card-title" style={{color:"blue"}}>About Us</h1>
                <p className="card-text"> {aboutus.aboutus_description}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <img src={taxhome} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className='container my-5 p-2 bg-dark'>
        <div className='p-2'>
          <h1 className='text-center text-white'>
            <span>Vedic Tax Features</span>
          </h1>
        </div>
        <div className='text-center text-white'>
          <p>
            {aboutus.principle_description}
          </p>
        </div>
        {
          principles.map(principle => {
            return (
              <div key={principle.id}>
                <div className="card m-3" data-aos="zoom-in">
                  <div className="card-body">
                    <h5 className="card-title text-center">{principle.title}</h5>
                    <p className="card-text text-center"> {principle.description}</p>
                  </div>
                </div>
              </div>
            )
          }
          )
        }
      </div>
      <div className="container">
        <div className='text-center m-3'>
          <h1 style={{color:"blue"}}> What Make Us Best</h1>
        </div>
        <section className='mt-3 p-3'>
          <div className='container flex flex-col justify-center justify-between'>
            <div className='row justify-content-center mx-auto mb-3'>
              {
                aboutus['whatmakesusbest'].map(value => {
                  return(
                    <div key={value.id} className='d-flex mt-4 flex-col col-sm-3'>
                      <div className="card">
                    {/* < div>
                      <img src={value.img} alt="Card image cap" />
                    </div> */}
                    <div className="card-body">
                      <span>
                        <h5 className="font-weight-bold card-title" style={{fontWeight:"bold"}}>{value.title}</h5>
                      </span>
                      <p className="card-text">{value.description}</p>
                    </div>
                  </div>
                </div>
                  )
                })
              }
            </div>
          </div>
        </section>
      </div>

    </section>
  )
}

export default About