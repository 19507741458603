import Axios from 'axios';
import url from '../../constants'

import { firestore } from '../../firebase';
import { collection, addDoc } from "firebase/firestore";

async function postFormResponse(data) {
    const postContactUrl= url + '/contactus'
    return await Axios.post(postContactUrl, {
      name : data.name,
      email : data.email,
      mobileNo : data.phone,
      serviceType : data.service,
      message : data.message
    })
}


const addDataToFirestore = async (data) => {
    try { 
      const collectionRef = collection(firestore,'contactus');
      await addDoc(collectionRef, data)
    } catch (error) {
      console.error('Error adding data to Firestore: ', error);
    }
  };

export {
  postFormResponse,
  addDataToFirestore
}