import React, { useEffect, useState } from 'react'
import services from '../../assets/json/services.json'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const Service = () => {

  let { id } = useParams()
  const [service, setService] = useState(undefined);
  const navigate = useNavigate()

  useEffect(() => {
    const service = services.find((service) => service['id'] == id);
    setService(service)
  }, [])
  
  const navigateTo = (toUrl) => {
    navigate(toUrl)
  }

  return (
    (service != undefined) ?
      <>
        <div className="container flex flex-col justify-center justify-between">
          <div className='row align-items-center mx-auto'>
            <div className="col-md-6 flex flex-col justify-center p-3 text-center rounded" data-aos="fade-left">
              <h1 className="font-bold leading-none">{service['name']}</h1>
              <p className="mt-3"> {service['description']}
              </p>
              <div className="flex flex-col items-center justify-center flex-row justify-start">
                <button className="btn btn-primary rounded" type="submit" onClick={() => navigateTo('/contact')}>{service['serviceButtonText']}</button>
              </div>
            </div>
            <div className="col-md-6 flex items-center justify-center" data-aos="fade-right">
              <img src={require(`../../assets/images/${service['mainimage']}`)} alt="" style={{ height: "350px" }} />
            </div>
          </div>
        </div>

        <section className='mt-3 bg-dark p-3'>
          <div className='container flex flex-col justify-center justify-between'>
            <h3 class="text-center mb-1 pb-2 text-primary fw-bold">Documents Required</h3>
            <p class="text-center mb-3 text-white">
            Discover the documents needed for a seamless service experience listed below.
            </p>
            <div className='row justify-content-center mx-auto mb-3'>
              {
                service['documents'] &&
                service['documents'].map(document => <div className='d-flex mt-4 flex-col col-sm-3'>
                  <div className="card">
                    <div className="card-body">
                      <span>
                        <h5 className="font-weight-bold card-title">{document.name}</h5>
                      </span>
                      <p className="card-text">{document.description}</p>
                    </div>
                  </div>
                </div>
                )
              }
            </div>
          </div>
        </section>

        <section className='mt-3'>
          <h3 class="text-center mb-1 pb-2 text-primary fw-bold">FAQ</h3>
          <p class="text-center mb-5">
            Find the answers for the most frequently asked questions below
          </p>

          <div className='container'>
            <div class="row">
              {
                service['faq'] &&
                service['faq'].map(faq => <div class="col-md-6 col-lg-4 mb-4">
                  <h6 class="mb-3 text-primary"><i class="text-primary"></i> {faq.question}</h6>
                  <p>
                    {faq.ans}
                  </p>
                </div>)
              }
            </div>
          </div>
        </section>

      </>
      :
      <div>
        Service Not found
      </div>

  )
}

export default Service